import React from "react";
import { Link } from "gatsby";

const About = () => {
  return (
    <section className="mt-10 text-xl font-light border-black text-swa-2">
      <div className="px-4 py-16 mx-auto max-w-7xl sm:pt-24 sm:pb-16 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="mt-1 text-4xl font-extrabold text-swa-1 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Fundraising
          </h1>
          <p className="max-w-5xl mx-auto mt-5 text-xl text-gray-500">
            Singapore Women’s Association (SWA) runs a big charity fund-raiser
            on an annual basis. It is the only time we garner all our voluntary
            resources to raise funds to support our programs and community
            outreach.
            <br />
            <br />
            The net proceeds are used throughout the year for our Vision Care
            program, old folks home projects, community hospitals projects and
            our selected beneficiaries. We are listed as an IPC charity
            organization allowing individuals and corporates to receive tax
            relieves for donations.
            <br />
            <br />
            The charity dinner is also the finale for the{" "}
            <Link to="/mspi">
              <span className="font-bold text-swa-1">
                Miss Singapore Pageant
              </span>
            </Link>
            . The young ladies are transformed from months of gruelling training
            and charity work. We unveil them at this purposeful and meaningful
            event.
            <br />
            <br />
            Do join us on this journey by attending our fundraising charity
            dinner or being a corporate sponsor. Every bit counts as we give
            back to society what we have gained from it.
          </p>
    
         
          <h2 className="mt-10 mb-5 text-xl font-bold text-swa-1 sm:text-3xl sm:tracking-tight lg:text-5xl">
            47th SWA Annual Charity Gala 2024
          </h2>
          <div className="inline-block mt-5 rounded-md shadow">
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/swa-admin-23d1e.appspot.com/o/download%2F47th%20Charity%20Dinner%20e-booklet-compressed.pdf?alt=media&token=8d60ab25-ec85-42db-9dcf-c8bfa792ce71&utm_campaign=charitydinner&utm_source=sponsorpage"
                  className="flex items-center justify-center px-3 py-3 mt-3 text-lg font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                  target="_blank"
                >
                  Download Souvenir Programme Booklet (PDF 6MB)
                </a>
              </div>
              <p className="max-w-2xl mx-auto mt-3 text-lg text-gray-500 sm:mt-4">
                We're excited to celebrate the 70th anniversary of the Singapore
                Women's Association. Inside, you'll find details on the event
                schedule, photos of the finalists for the Miss Singapore Pageant
                International 2024, and information about prizes for Guess The
                Winner. The booklet also provides an overview of the Singapore
                Women's Association 70 years history. We hope you enjoy this
                special evening filled with pageantry, philanthropy, and
                community. Thank you for your support.
              </p>


              <img
                src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1720663662/participatePage/kdxua2eefnih4lbk2nug.webp"
                alt="SWA Charity Gala"
                width={1000}
                height={700}
                className="mx-auto mt-5 sm:max-w-xl"
              />
     
          <div className="inline-block mt-5 rounded-md shadow">
   
            <a
              href="https://www.canva.com/design/DAF3IGBVKoE/uG0OrofrBOHQq1yn_0B47Q/edit?utm_content=DAF3IGBVKoE&utm_campaign=designshare&utm_medium=link2&utm_source=sharebuttong"
              className="flex items-center justify-center px-5 py-3 mb-5 text-2xl font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
              target="_blank"
            >
              See sponsorship opportunities
            </a>
          </div>

        </div>
      </div>
    </section>
  );
};

export default About;
