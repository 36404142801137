import React from "react";
import { browserName } from "react-device-detect";

import Layout3 from "../../components/layout/layout3";
import Header from "../../components/sections/fundraising/Header";
import About from "../../components/sections/fundraising/About";
import Speech from "../../components/sections/fundraising/Speech";
import Sponsors from "../../components/sections/fundraising/Sponsors";

import SEO from "../../components/seo";

import { StaticImage } from "gatsby-plugin-image";

// import MSPIvideo from "../../images/mspi.mp4";
// import MSPIphoto from "../../images/mspi.jpg";

const FundRaising = () => {
  // console.log("browser: ", browserName);

  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout3>
        <SEO
          title="Annual Charity Gala Dinner"
          description="Singapore Women’s Association (SWA) runs a big charity fund-raiser on an annual basis. It is the only time we garner all our voluntary resources to raise funds to support our programs and community outreach."
        />
        {/* {browserName !== "Safari" && <Header />} */}

        <About />
        {/* <section className="mx-1 mt-10 text-xl font-light border-black text-swa-2">
          <div className="grid grid-cols-2 my-5 gap-x-1 gap-y-1 sm:grid-cols-3 sm:gap-x-1 lg:grid-cols-4 xl:gap-x-1">
            <div className="block w-full overflow-hidden rounded-lg group ">
              <StaticImage
                src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1665547072/charityDinnerPage/bcxvchwfghgzdn3oa9sa.webp"
                alt="Fundraising"
                aspectRatio={10 / 7}
                objectPosition={"50% 0%"}
              />
            </div>
            <div className="block w-full overflow-hidden rounded-lg group ">
              <StaticImage
                src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636687752/charityDinnerPage/ym853e0wmihc3ovixr1o.jpg"
                alt="Fundraising"
                aspectRatio={10 / 7}
                objectPosition={"50% 0%"}
              />
            </div>
            <div className="block w-full overflow-hidden rounded-lg group ">
              <StaticImage
                src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1665546627/charityDinnerPage/crae3x39h9iniuzzabwz.webp"
                alt="Fundraising"
                aspectRatio={10 / 7}
                objectPosition={"50% 0%"}
              />
            </div>
            <div className="block w-full overflow-hidden rounded-lg group ">
              <StaticImage
                src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1665547593/charityDinnerPage/kyfhpcyrl5erwy5qmbt5.webp"
                alt="Fundraising"
                aspectRatio={10 / 7}
                objectPosition={"50% 50%"}
              />
            </div>
            <div className="block w-full overflow-hidden rounded-lg group ">
              <StaticImage
                src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1665547224/charityDinnerPage/uz6hxvhdlj2ol5o7s1gg.webp"
                alt="Fundraising"
                aspectRatio={10 / 7}
                objectPosition={"50% 0%"}
              />
            </div>
            <div className="block w-full overflow-hidden rounded-lg group ">
              <StaticImage
                src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1665546733/charityDinnerPage/smbhdo1cbwvkd4dan97a.webp"
                alt="Fundraising"
                aspectRatio={10 / 7}
                objectPosition={"50% 50%"}
              />
            </div>
            <div className="block w-full overflow-hidden rounded-lg group ">
              <StaticImage
                src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636687752/charityDinnerPage/qiplqfini0e3x2vhupqq.jpg"
                alt="Fundraising"
                aspectRatio={10 / 7}
                objectPosition={"50% 0%"}
              />
            </div>

            <div className="block w-full overflow-hidden rounded-lg group ">
              <StaticImage
                src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1665547378/charityDinnerPage/mc2opdgvz52guttckbgn.webp"
                alt="Fundraising"
                aspectRatio={10 / 7}
                objectPosition={"50% 0%"}
              />
            </div>
          </div>
        </section> */}
{/* 
        <Speech />
        <Sponsors /> */}
      </Layout3>
    </>
  );
};

export default FundRaising;
